<template>
  <abnormal-warning :datas="[5, 'viewBiaoMianException', 'editBiaoMianException']"></abnormal-warning>
</template>
<script>
import AbnormalWarning from '@/components/threshold/AbnormalWarning.vue'
export default {
  components: {
    AbnormalWarning,
  },
  data() {
    return {}
  },
}
</script>
